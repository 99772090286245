<template>
  <div>
    <div>
      <!-- class="router-view-container" -->
      <h1 class="page_title mb-5">Hi, {{ $store.state.getWebData.param.uname }}</h1>

      <main class="flex flex-col space-y-5 xl:flex-row xl:space-y-0 xl:space-x-5">
        <!-- 輕鬆建立您的形象官網 -->
        <!-- <div v-if="data.item1.ov_state1 == 0 || data.item1.ov_state2 == 0 || data.item1.ov_state3 == 0">
          <h1 class="">3個步驟，輕鬆建立您的形象官網</h1>
          <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
            <div class="data_container flex flex-col">
              <router-link :to="{ name: 'basic_general' }" class="h-full">
                <div class="flex items-center justify-between">
                  <h2>1. 商店設定</h2>
                  <div>
                    <svg-icon icon-class="success" className="xl gray-divide" v-if="data.item1.ov_state1 == 0"></svg-icon>
                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                  </div>
                </div>
                <div class="mt-4"><p>填寫您的專屬商店資料</p></div>
              </router-link>
            </div>

            <div class="data_container flex flex-col">
              <router-link :to="{ name: 'setting_domains' }" class="h-full">
                <div class="flex items-center justify-between">
                  <h2>2. 網域設定</h2>
                  <div>
                    <svg-icon icon-class="success" className="xl gray-divide" v-if="data.item1.ov_state2 == 0"></svg-icon>
                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                  </div>
                </div>
                <div class="mt-4">
                  <p>為您的網站建立出獨有的網址</p>
                </div>
              </router-link>
            </div>

            <div class="data_container flex flex-col">
              <router-link :to="{ name: 'themes' }" class="h-full">
                <div class="flex items-center justify-between">
                  <h2>3. 佈景主題設定</h2>
                  <div>
                    <svg-icon icon-class="success" className="xl gray-divide" v-if="data.item1.ov_state3 == 0"></svg-icon>
                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                  </div>
                </div>
                <div class="mt-4">
                  <p>一個適合你品牌的商店設計能夠吸引並留住顧客，選擇一個最適合你品牌的商店設計</p>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->

        <!-- 輕鬆建立您的電子商務 -->
        <!-- <div v-if="data.item1.ov_state4 == 0 || data.item1.ov_state5 == 0 || data.item1.ov_state6 == 0">
          <h1 class="">3個步驟，輕鬆建立您的電子商務</h1>
          <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
            <div class="data_container flex flex-col">
              <router-link :to="{ name: 'products' }" class="h-full">
                <div class="flex items-center justify-between">
                  <h2>1. 商品設定</h2>
                  <div>
                    <svg-icon icon-class="success" className="xl gray-divide" v-if="data.item1.ov_state4 == 0"></svg-icon>
                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                  </div>
                </div>
                <div class="mt-4">
                  <p>先增加商品名稱及商品圖片來創建你的第一件商品，待完成所有基本設定後探索更多功能</p>
                </div>
              </router-link>
            </div>
            <div class="data_container flex flex-col">
              <router-link :to="{ name: 'store_payments' }" class="h-full">
                <div class="flex items-center justify-between">
                  <h2>2. 金流設定</h2>
                  <div>
                    <svg-icon icon-class="success" className="xl gray-divide" v-if="data.item1.ov_state5 == 0"></svg-icon>
                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                  </div>
                </div>
                <div class="mt-4">
                  <p>設定你希望提供予顧客的付款方式</p>
                </div>
              </router-link>
            </div>
            <div class="data_container flex flex-col">
              <router-link :to="{ name: 'store_shipping' }" class="h-full">
                <div class="flex items-center justify-between">
                  <h2>3. 物流設定</h2>
                  <div>
                    <svg-icon icon-class="success" className="xl gray-divide" v-if="data.item1.ov_state6 == 0"></svg-icon>
                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                  </div>
                </div>
                <div class="mt-4">
                  <p>為你的顧客設定不同的送貨方式，每種方式都可以設定不同的收費模式及運費</p>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->
        <!-- <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
          <div
            class="data_container flex flex-col"
            v-if="
              data.item1.ov_state1 == 1 &&
              data.item1.ov_state2 == 1 &&
              data.item1.ov_state3 == 1 &&
              data.item1.ov_state4 == 1 &&
              data.item1.ov_state5 == 1 &&
              data.item1.ov_state6 == 1
            "
          >
            <h2 style="padding: 0">開店六步驟</h2>
            <div class="flex flex-grow">
              <router-link :to="{ name: 'basic_general' }" class="w-1/5 flex flex-col items-center justify-center">
                <p>商店</p>
                <svg-icon icon-class="success" className="xxl gray-divide" v-if="data.item1.ov_state1 == 0"></svg-icon>
                <svg-icon icon-class="success" className="xxl success" v-else></svg-icon>
              </router-link>
              <router-link :to="{ name: 'setting_domains' }" class="w-1/5 flex flex-col items-center justify-center">
                <p>網域</p>
                <svg-icon icon-class="success" className="xxl gray-divide" v-if="data.item1.ov_state2 == 0"></svg-icon>
                <svg-icon icon-class="success" className="xxl success" v-else></svg-icon>
              </router-link>
              <router-link :to="{ name: 'themes' }" class="w-1/5 flex flex-col items-center justify-center">
                <p>佈景</p>
                <svg-icon icon-class="success" className="xxl gray-divide" v-if="data.item1.ov_state3 == 0"></svg-icon>
                <svg-icon icon-class="success" className="xxl success" v-else></svg-icon>
              </router-link>
              <router-link :to="{ name: 'products' }" class="w-1/5 flex flex-col items-center justify-center">
                <p>商品</p>
                <svg-icon icon-class="success" className="xxl gray-divide" v-if="data.item1.ov_state4 == 0"></svg-icon>
                <svg-icon icon-class="success" className="xxl success" v-else></svg-icon>
              </router-link>
              <router-link :to="{ name: 'store_payments' }" class="w-1/5 flex flex-col items-center justify-center">
                <p>金流</p>
                <svg-icon icon-class="success" className="xxl gray-divide" v-if="data.item1.ov_state5 == 0"></svg-icon>
                <svg-icon icon-class="success" className="xxl success" v-else></svg-icon>
              </router-link>
              <router-link :to="{ name: 'store_shipping' }" class="w-1/5 flex flex-col items-center justify-center">
                <p>物流</p>
                <svg-icon icon-class="success" className="xxl gray-divide" v-if="data.item1.ov_state6 == 0"></svg-icon>
                <svg-icon icon-class="success" className="xxl success" v-else></svg-icon>
              </router-link>
            </div>
          </div>
          <div class="data_container">
            <h2>我的商店</h2>
            <div class="space-y-2">
              <p>
                網&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址:&nbsp;&nbsp;
                <a :href="data.item1.wlink" target="_blank">{{ data.item1.wlink }}</a>
              </p>
              <p>店&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名:&nbsp;&nbsp;{{ $store.state.getWebData.param.sname }}</p>
              <p>手 機 號 碼: {{ data.item1.tel }}</p>
              <p>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址:&nbsp;&nbsp;{{ data.item1.address1 }}</p>
            </div>
          </div>
          <div class="data_container">
            <h2>我的方案</h2>
            <div class="space-y-2">
              <p>方案名稱:&nbsp;&nbsp;{{ data.item1.plan }}</p>
              <p>起訖日期:&nbsp;&nbsp;{{ data.item1.plandate }}</p>
            </div>
          </div>
        </div> -->

        <section class="flex-grow w-full space-y-5">
          <!-- 主要數據 -->
          <div>
            <!-- <h1 class="mb-4">今日數據</h1> -->
            <div class="sm_data_grid">
              <div class="data_container" :class="{ bg_danger: data.item2.num1 > 0 }">
                <div class="sm_data">
                  <div class="icon_bg danger">
                    <svg-icon icon-class="credit_card" class="xxl danger" :class="{ danger: data.item2.num1 > 0 }"></svg-icon>
                  </div>

                  <div class="num_div">
                    <h2 class="title">未付款訂單</h2>
                    <p class="num">{{ data.item2.num1 }}</p>
                  </div>
                </div>
              </div>

              <div class="data_container" :class="{ bg_warning: data.item2.num2 > 0 }">
                <div class="sm_data">
                  <div class="icon_bg warning">
                    <svg-icon icon-class="shipping_van" class="xxl warning" :class="{ warning: data.item2.num2 > 0 }"></svg-icon>
                  </div>

                  <div class="num_div">
                    <h2 class="title">未出貨訂單</h2>
                    <p class="num">{{ data.item2.num2 }}</p>
                  </div>
                </div>
              </div>

              <!-- <div class="data_container">
                <h2>低於安全庫存</h2>
                <div class="flex items-center justify-center">
                  <h1>{{ data.item2.num3 }}</h1>
                </div>
              </div>
              <div class="data_container">
                <h2>留言未讀數</h2>
                <div class="flex items-center justify-center">
                  <h1>{{ data.item2.num4 }}</h1>
                </div>
              </div> -->
            </div>
          </div>

          <!-- 篩選數據標題 -->
          <div class="relative">
            <h1 class="chart_title">篩選數據</h1>
            <vs-select
              class="absolute right-0 top-1/2 transform -translate-y-1/2"
              placeholder=""
              v-model="dateSelect"
              style="max-width: 110px"
              @change="loadData"
            >
              <vs-option label="今日" value="0"> 今日 </vs-option>
              <vs-option label="昨日" value="1"> 昨日 </vs-option>
              <vs-option label="過去7日" value="2"> 過去7日 </vs-option>
              <vs-option label="過去30日" value="3"> 過去30日 </vs-option>
            </vs-select>
          </div>

          <!-- 篩選數據 -->
          <div class="sm_data_grid">
            <div class="data_container">
              <div class="sm_data">
                <div class="icon_bg success">
                  <svg-icon icon-class="trophy" class="xxl success"></svg-icon>
                </div>

                <div class="num_div">
                  <h2 class="title">營業額</h2>
                  <p class="num">{{ data.item3.num2 }}</p>
                </div>
              </div>
            </div>
            <div class="data_container">
              <div class="sm_data">
                <div class="icon_bg success">
                  <svg-icon icon-class="orders" class="xxl success"></svg-icon>
                </div>

                <div class="num_div">
                  <h2 class="title">訂單數</h2>
                  <p class="num">{{ data.item3.num1 }}</p>
                </div>
              </div>
            </div>
            <div class="data_container">
              <div class="sm_data">
                <div class="icon_bg success">
                  <svg-icon icon-class="new_member" style="width: 2rem; height: auto" class="success success_stroke"></svg-icon>
                </div>

                <div class="num_div">
                  <h2 class="title">會員數</h2>
                  <p class="num">{{ data.item3.num3 }}</p>
                </div>
              </div>
            </div>
            <div class="data_container">
              <div class="sm_data">
                <div class="icon_bg success">
                  <svg-icon icon-class="website_views" class="xxl success success_stroke"></svg-icon>
                </div>

                <div class="num_div">
                  <h2 class="title">網站瀏覽數</h2>
                  <p class="num">{{ data.item3.num4 }}</p>
                </div>
              </div>
            </div>
          </div>

          <h1 class="chart_title">數據圖表</h1>

          <!-- 圖表 -->
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div class="data_container">
              <h2 class="title">營業額</h2>
              <div class="flex items-center justify-center">
                <bar-chart :chart-data="data.item3.table2" :options="chartOptions" class="bar-chart" />
              </div>
            </div>
            <div class="data_container">
              <h2 class="title">訂單數</h2>
              <div class="flex items-center justify-center">
                <bar-chart :chart-data="data.item3.table1" :options="chartOptions" class="bar-chart" />
              </div>
            </div>

            <div class="data_container">
              <h2 class="title">會員註冊數</h2>
              <div class="flex items-center justify-center">
                <bar-chart :chart-data="data.item3.table3" :options="chartOptions" class="bar-chart" />
              </div>
            </div>
            <div class="data_container">
              <h2 class="title">瀏覽數</h2>
              <div class="flex items-center justify-center">
                <bar-chart :chart-data="data.item3.table4" :options="chartOptions" class="bar-chart" />
              </div>
            </div>
            <div class="data_container">
              <h2 class="title">熱門商品銷售</h2>
              <div class="flex items-center justify-center">
                <bar-chart :chart-data="data.item3.table5" :options="chartOptions" class="bar-chart" />
              </div>
            </div>
          </div>
        </section>

        <!-- 我的商店 -->
        <section class="flex-shrink-0 w-full xl:w-80 relative">
          <div class="bg-white rounded-xl p-4 space-y-5 w-full xl:fixed xl:w-80">
            <h1 class="big_title">我的商店</h1>

            <!-- 商店資料 -->
            <div class="flex flex-col items-center space-y-2">
              <div class="w-32 h-32 overflow-hidden rounded-full flex items-center justify-center border p-4 mb-6">
                <img :src="$store.state.getWebData.param.pclogo" />
              </div>

              <h2>{{ data.item1.sname }}</h2>
              <div class="flex items-center justify-center space-x-2 text-gray-400">
                <a :href="data.item1.wlink" target="_blank" rel="noopener noreferrer"> {{ data.item1.wlink }}</a>
                <button @click="copyToClipboard">
                  <svg-icon icon-class="copy" class="xs" style="fill: currentColor"></svg-icon>
                </button>
              </div>
              <!-- <p v-if="data.item1.address1" class="text-gray-400">{{ data.item1.address1 }}</p>
            <p v-if="data.item1.tel" class="text-gray-400">{{ data.item1.tel }}</p> -->
            </div>

            <hr />

            <!-- 方案資料 -->
            <div class="space-y-2">
              <div>
                <h3 class="">方案名稱</h3>
                <p class="text-gray-400">
                  {{ data.item1.plan }}
                </p>
              </div>
              <div>
                <h3 class="">起訖日期</h3>
                <p class="text-gray-400">
                  {{ data.item1.plandate }}
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import BarChart from '../components/chart/BarChart.vue'

export default {
  name: 'overview',
  components: {
    BarChart,
  },
  data() {
    return {
      dateSelect: '0',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
      },
      data: {},
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/overview/getOverviewData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          dtype: this.dateSelect,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.data = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 複製優惠碼到剪貼簿
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.data.item1.wlink)
        this.$myVS.openNoti('複製成功', '', undefined)
      } catch (err) {
        alert('您的瀏覽器版本不支援此種複製方式，請手動複製。', err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// h1 {
//   @apply text-gray-text_dark text-lg font-medium mb-5;
// }

// h2 {
//   @apply text-gray-text_dark text-base font-medium pb-4;
// }

// p {
//   @apply text-gray-text_light text-sm break-all;
// }

.big_title {
  @apply text-base font-medium text-gray-text_dark;
}

.chart_title {
  @apply font-medium text-gray-400 py-4;
  font-size: 18px;
}

.data_container {
  min-height: 100px;
  @apply rounded-xl p-4 bg-white;

  .sm_data {
    @apply h-full flex items-center space-x-3;
  }

  .title {
    @apply text-gray-400;
  }

  .icon_bg {
    @apply p-2 bg-gray-200 flex items-center justify-center rounded-full overflow-hidden w-14 h-14 flex-shrink-0;
    &.danger {
      @apply bg-danger bg-opacity-10;
    }
    &.warning {
      @apply bg-warning bg-opacity-20;
    }
    &.success {
      @apply bg-success bg-opacity-10;
    }
  }

  .num_div {
    @apply flex flex-col space-y-1;

    .num {
      @apply text-lg text-gray-text_dark font-bold;
    }
  }

  &.bg_danger,
  &.bg_warning {
    @apply text-white;
    .icon_bg {
      @apply bg-white;
    }
    .title,
    .num {
      @apply text-white;
    }
  }

  &.bg_danger {
    @apply bg-danger;
  }

  &.bg_warning {
    @apply bg-warning;
  }
}

.sm_data_grid {
  @apply grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-5;
}

.bar-chart {
  @apply w-full mt-4;
}
</style>
